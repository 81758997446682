import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { productsReducer, ProductsState } from './productsSlice';
import { authReducer, AuthState } from './authSlice';
import { flashReducer, FlashState } from './flashSlice';
import { orderReducer, OrderState } from './orderSlice';
import { reduxStorage } from './storage';
import { persistReducer, persistStore } from 'redux-persist';
import { uniqueProductsReducer, UniqueProductsState } from './uniqueProductsSlice';
import { fieldOptionsReducer, FieldOptionsState } from './fieldOptionsSlice';

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  thunk,
];

const rootPersistConfig = {
  key: 'root',
  storage: reduxStorage,
  blacklist: ['flash'],
};

export type RootState = {
  auth: AuthState
  products: ProductsState
  flash: FlashState
  order: OrderState
  uniqueProducts: UniqueProductsState
  fieldOptions: FieldOptionsState
}

const rootReducer = persistReducer(
  rootPersistConfig,
  combineReducers(
    {
      auth: authReducer,
      products: productsReducer,
      order: orderReducer,
      flash: flashReducer,
      uniqueProducts: uniqueProductsReducer,
      fieldOptions: fieldOptionsReducer
    },
  ),
);

export const store = configureStore(
  {
    reducer: rootReducer,
    middleware,
  },
);

export const persistor = persistStore(store);
