import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requiredCheck } from './productsSliceHelpers';
import { productSizeRuleCorrectCheck, productSizeRuleHelper } from '../hooks/useProductSizeRuleValid';
import { ValidationOption } from '../hooks/useProductValidation';

export type ValidationState =
  'incomplete' | 'waiting' | 'processing' | 'failed' | 'correct'

export type SizeValidationState = 'unknown' | 'incomplete' | 'bad' | 'good'

export type Product = {
  active: boolean
  product_id?: number | string
  quantity?: number | string
  extra_count?: number;
  features: {
    code: string
    keyed_value?: number | string
    option_code?: string
    option_value?: number
  }[]
  note: string,
  minorder?: number,
  order_multiple?: number,
  vastagsag?: {
    code: string
    description: string
  }[]
  rules?: ProductSizeRulesRaw
}

export type FeatureType = Product['features'][number]

export type ProductSizeRulesHelper = {
  min: number
  max: number
}

export type ProductSizeRules = {
  height: ProductSizeRulesHelper
  width: ProductSizeRulesHelper
}[]

export type ProductSizeRulesRaw = {
  min_h: number
  max_h: number
  min_w: number
  max_w: number
}[]

export type ProductWithKey = Product & {
  key_id: number
  error?: {
    field?: string
    text: string
  }
  validationState: ValidationState
  sizeValidationState: SizeValidationState
  sizeRules?: ProductSizeRules
  sizeRulesFromValidation?: ProductSizeRules
  extrasOptions?: ValidationOption[]
}

export type FieldType = {
  label: string
  code: string
  type: 'select' | 'number'
}

export type ProductsState = {
  products: ProductWithKey[]
  error?: string
  categoryId?: string | number
  fields?: FieldType[]
  lastProductKeyId: number
}

const initialState: ProductsState = {
  products: [],
  error: undefined,
  lastProductKeyId: 1,
};

type ProductReducer<T = void> = CaseReducer<ProductsState, PayloadAction<T>>

type Reducers = {
  loadProducts: ProductReducer<Product[]>
  addProduct: ProductReducer<Product>
  addProducts: ProductReducer<Product[]>
  addProductAt: ProductReducer<{ product: Product, index: number }>
  resetProducts: ProductReducer

  modifyProductIdById: ProductReducer<{ key_id: number, id: number }>
  modifyProductQuantityById: ProductReducer<{ id: number, quantity: number | string }>
  modifyProductNoteById: ProductReducer<{ id: number, note: string }>
  modifyProductFeatureById: ProductReducer<{ id: number; feature: Product['features'][number]; fields?: FieldType[] }>
  modifyProductExtraCountById: ProductReducer<{ id: number, extraCount?: number }>
  modifyProductValidationState: ProductReducer<{
    id: number,
    validationState: ValidationState,
    error?: ProductWithKey['error']
  }>
  modifyProductSizeRules: ProductReducer<{
    id: number
    sizeRules?: ProductSizeRules,
    type: 'validation' | 'normal'
  }>
  modifyProductExtrasOptions: ProductReducer<{
    id: number
    options?: ValidationOption[],
  }>

  toggleProductById: ProductReducer<number>

  removeProductFeatureById: ProductReducer<{ id: number; featureCode: string }>
  removeProductById: ProductReducer<number>
  removeActiveProducts: ProductReducer
  removeFirstEmpty: ProductReducer
  removeExtrasFromProductById: ProductReducer<number>

  activateAll: ProductReducer
  deActivateAll: ProductReducer

  setCategoryId: ProductReducer<{ catId: number | string | undefined, fields: FieldType[] | undefined }>
  setFields: ProductReducer<FieldType[]>
}

export const productsSlice = createSlice<ProductsState, Reducers>(
  {
    name: 'products',
    initialState,
    reducers: {
      loadProducts: (state, { payload }) => {
        let i = state.lastProductKeyId;
        state.products = payload.map((product) => ({
          ...product,
          key_id: ++i,
          validationState: requiredCheck(product as ProductWithKey, state.fields ?? []) ?
            'waiting' : 'incomplete',
          sizeValidationState: 'unknown',
        }));
        state.lastProductKeyId = i;
      },
      addProduct: (state, { payload }) => {
        payload.quantity = payload.quantity ?? 1;
        state.products.push({
          ...payload,
          key_id: state.lastProductKeyId + 1,
          validationState: requiredCheck(payload as ProductWithKey, state.fields ?? []) ?
            'waiting' : 'incomplete',
          sizeValidationState: 'unknown',
        });
        state.lastProductKeyId++;
      },
      addProducts: (state, { payload }) => {
        const firstProduct = state.products[0];
        if (firstProduct) {
          if (firstProduct.product_id === undefined) {
            state.products.shift();
          }
        }
        payload.forEach((product) => {
          product.quantity = product.quantity ?? 1;
        });
        let i = state.lastProductKeyId;
        state.products = state.products.concat(payload.map((product) => ({
          ...product,
          key_id: ++i,
          validationState:
            requiredCheck(product as ProductWithKey, state.fields ?? []) ?
              'waiting' : 'incomplete',
          sizeValidationState: 'unknown',
        })));
        state.lastProductKeyId = i;
      },
      addProductAt: (state, { payload: { product, index } }) => {
        product.quantity = product.quantity ?? 1;
        state.products.splice(index + 1, 0, {
          ...product,
          key_id: state.lastProductKeyId + 1,
          validationState: 'incomplete',
          sizeValidationState: 'unknown',
        });
        state.lastProductKeyId++;
      },
      modifyProductIdById: (state, { payload: { key_id, id } }) => {
        const index = state.products.findIndex(({ key_id: productId }) => productId === key_id);

        if (index !== -1) {
          state.products[index].product_id = id;
          state.products[index].features = [];
          state.products[index].validationState = 'incomplete';
          state.products[index].extra_count = undefined;
        }
      },
      modifyProductQuantityById: (state, { payload: { id, quantity } }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1) {
          state.products[index].quantity = quantity;
          if (quantity === '' || quantity === undefined) {
            state.products[index].validationState = 'incomplete';
          } else if (state.products[index].validationState === 'incomplete') {
            if (requiredCheck(state.products[index], state.fields ?? [])) {
              state.products[index].validationState = 'waiting';
            }
          }
        }
      },
      modifyProductNoteById: (state, { payload: { id, note } }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1)
          state.products[index].note = note;
      },
      removeProductFeatureById: (
        state,
        { payload: { id, featureCode } },
      ) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);
        if (index !== -1) {
          const featureIndex = state.products[index].features.findIndex(({ code }) => code === featureCode);
          state.products[index].features.splice(featureIndex, 1);
        }
      },
      modifyProductValidationState: (state, { payload: { id, validationState, error } }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);
        if (index !== -1) {
          if (validationState === 'correct' || validationState === 'failed') {
            if (state.products[index].validationState === 'processing') {
              state.products[index].validationState = validationState;
              if (error) {
                state.products[index].error = error;
              }
            }
          } else {
            state.products[index].validationState = validationState;
          }

          if (!error && validationState !== 'failed') {
            state.products[index].error = undefined;
          }
        }
      },
      modifyProductFeatureById: (
        state,
        { payload: { id, feature, fields } },
      ) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1) {
          if (!feature.code)
            return;

          const featureIndex = state.products[index].features.findIndex(({ code }) => code === feature.code);
          if (featureIndex !== -1) {
            state.products[index].features[featureIndex] = feature;
          } else {
            state.products[index].features.push(feature);
          }

          if (fields) {

            const fieldIndex = fields.findIndex(({ code }) => code === feature.code);

            if (fieldIndex >= 0) {
              fields.slice(fieldIndex + 1, fields.length).forEach(field => {
                if (field.type !== 'select')
                  return;

                const featureIndex = state.products[index].features.findIndex(({ code }) => code === field.code);
                if (featureIndex !== -1) {
                  state.products[index].features.splice(featureIndex, 1);
                }
              });
            }

          }

          if (state.products[index].validationState === 'incomplete') {
            if (requiredCheck(state.products[index], fields ?? state.fields ?? [])) {
              state.products[index].validationState = 'waiting';
            }
          } else {
            if (!requiredCheck(state.products[index], fields ?? state.fields ?? [])) {
              state.products[index].validationState = 'incomplete';
            }
          }
        }

        const product = state.products[index];
        const height = product.features.find(({ code }) => code === 'MAG')?.keyed_value;
        const width = product.features.find(({ code }) => code === 'SZEL')?.keyed_value;

        const currentSizeRules = productSizeRuleHelper(
          product.validationState,
          product.sizeRules,
          product.sizeRulesFromValidation,
        );

        const [heightCorrect, widthCorrect] = productSizeRuleCorrectCheck(currentSizeRules, width, height);

        if (height === undefined || height === '' || width === undefined || width === '')
          state.products[index].sizeValidationState = 'incomplete';
        else if (heightCorrect && widthCorrect)
          state.products[index].sizeValidationState = 'good';
        else
          state.products[index].sizeValidationState = 'bad';
      },
      modifyProductSizeRules: (state, { payload: { id, sizeRules, type } }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1) {
          if (type === 'normal')
            state.products[index].sizeRules = sizeRules;
          else if (type === 'validation')
            state.products[index].sizeRulesFromValidation = sizeRules;

          const product = state.products[index];
          const height = product.features.find(({ code }) => code === 'MAG')?.keyed_value;
          const width = product.features.find(({ code }) => code === 'SZEL')?.keyed_value;

          const currentSizeRules = productSizeRuleHelper(
            product.validationState,
            product.sizeRules,
            product.sizeRulesFromValidation,
          );

          const [heightCorrect, widthCorrect] = productSizeRuleCorrectCheck(currentSizeRules, width, height);

          if (height === undefined || height === '' || width === undefined || width === '')
            state.products[index].sizeValidationState = 'incomplete';
          else if (heightCorrect && widthCorrect)
            state.products[index].sizeValidationState = 'good';
          else
            state.products[index].sizeValidationState = 'bad';
        }
      },
      modifyProductExtrasOptions: (state, { payload: { id, options } }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1) {
          state.products[index].extrasOptions = options;
        }
      },
      removeProductById: (state, { payload: id }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1)
          state.products.splice(index, 1);
      },
      toggleProductById: (state, { payload: id }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1)
          state.products[index].active = !state.products[index].active;
      },
      activateAll: (state) => {
        state.products.forEach((product) => product.active = true);
      },
      deActivateAll: (state) => {
        state.products.forEach((product) => product.active = false);
      },
      resetProducts: (state) => {
        state.lastProductKeyId = 1;
        state.products = [
          {
            active: true,
            features: [],
            note: '',
            quantity: 1,
            key_id: 1,
            validationState: 'incomplete',
            sizeValidationState: 'unknown',
          },
        ];
      },
      removeActiveProducts: (state) => {
        state.products = state.products.filter(({ active }) => !active);
      },

      removeFirstEmpty: (state) => {
        const firstProduct = state.products[0];
        if (firstProduct) {
          if (firstProduct.product_id === undefined) {
            state.products.shift();
          }
        }
      },
      modifyProductExtraCountById: (state, { payload: { id, extraCount } }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);

        if (index !== -1) {
          state.products[index].extra_count = extraCount;
        }
      },
      setCategoryId: (state, { payload: { catId, fields } }) => {
        if (fields === undefined && state.categoryId !== catId) {
          state.fields = fields;
        } else if (fields !== undefined) {
          state.fields = fields;
        }

        state.categoryId = catId;
      },
      setFields: (state, { payload: fields }) => {
        state.fields = fields;
      },
      removeExtrasFromProductById: (state, { payload: id }) => {
        const index = state.products.findIndex(({ key_id }) => id === key_id);
        if (index !== -1 && state.fields !== undefined) {
          state.products[index].features = state.products[index].features.filter(
            ({ code }) =>
              state.fields?.some(({ code: fieldCode }) => code === fieldCode),
          );
        }
      },
    },
  },
);

export const productsReducer = productsSlice.reducer;
export const productActions = productsSlice.actions;
